



























































































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Address from '../../../../store/entities/companycontext/address'
import log = abp.log.log;
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";

@Component
export default class CreateAddress extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    address: Address = new Address();

    countries: any;
    subdivisions: any;
    companies: any;
    addresses: any;

    data() {
        return {
            countries: [],
            subdivisions: [],
            companies: [],
            addresses: []
        }
    };

    checkChanged(val){
        if (!val){
            (this.$refs.thirdPartyAddress as any).clearSingleSelect();
            (this.$refs.thirdPartyCompany as any).clearSingleSelect();
        }
    }

    async getGeoSubdivisions(countryId: number) {
        if (countryId) {
            this.subdivisions = await this.$store.dispatch({
                type: 'subdivision/byCountryId',
                data: countryId
            })
        }
    }

    async isCompanyChange(val) {
        (this.$refs.thirdPartyAddress as any).clearSingleSelect();
        if (val) {
            await this.loadAddresses(val)
        }

    }

    async loadCompanies(query) {
        if (query !== null && query.length >= 1) {
            this.companies = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.address.thirdPartyCompanyId, null));
        } else {
            this.companies = [];
        }
    }

    async loadAddresses(companyId: number) {
        if (companyId) {
            this.addresses = await this.$store.dispatch('address/lookUp', companyId);
        } else {
            this.addresses = [];
        }
    }

    clearSubDivisionSelection() {
        (this.$refs.subdivision as any).clearSingleSelect();
    }

    async mounted() {
        if (this.$route.params["addressId"] == null) {
            this.address.companyId = parseInt(this.$route.params["companyId"]);
        } else {
            this.address = await this.$store.dispatch({
                type: 'address/get',
                data: parseInt(this.$route.params["addressId"])
            });
        }
        if (this.address.isThirdParty) {
            await this.loadAddresses(this.address.thirdPartyCompanyId);
        }
        this.countries = await this.$store.dispatch('country/lookUp');
        await this.getGeoSubdivisions(this.address.countryId);
    }

    async isCountryChange(val: number) {
        await this.getGeoSubdivisions(val);
        this.clearSubDivisionSelection();
    }

    props: {
        companyId: number
    }

    save() {
        (this.$refs.addressForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: (this.address.id == null) ? 'address/create' : 'address/update',
                    data: this.address
                });
                (this.$refs.addressForm as any).resetFields();
                this.$router.back();
            }
        })
    }

    cancel() {
        (this.$refs.addressForm as any).resetFields();
        this.$emit('input', false);
        this.$router.back();
    }

    async created() {
    }

    addressRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}
